<template>
  <div class="row">
    <div class="col-12 g c col-lg-4">
      <div class="card">
        <div class="card-body">
          <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered">
              <tbody>
                <tr>
                  <td>الطالب</td>
                  <td>{{ student.name }}</td>
                </tr>
                <tr>
                  <td>ولي الأمر</td>
                  <td>{{ parent.name }}</td>
                </tr>
                <tr>
                  <td>الصف</td>
                  <td>{{ student.classname }}</td>
                </tr>
                <tr>
                  <td>الفصل</td>
                  <td>{{ student.classroom }}</td>
                </tr>
                <tr>
                  <td>النوع</td>
                  <td>
                    {{
                      student.type
                        ? student.type
                            .replace("go", "ذهاب")
                            .replace("back", "عودة")
                            .replace("both", "ذهاب وعودة")
                        : ""
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">اختيار فترة معينة للتقارير</div>
          </div>
          <div class="form-group">
            <label for="">الفترة من </label>
            <input type="date" class="form-control" v-model="start" />
          </div>
          <div class="form-group">
            <label for="">الفترة إلى </label>
            <input type="date" class="form-control" v-model="end" />
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-primary" @click="getReport()">
              <i class="fa fa-search"></i>
              عرض التقرير
            </button>
          </div>
          <div v-if="loading" class="text-center g">جاري التحميل..</div>
          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">رحلة الطالب الحالية</div>
          </div>
          <div v-if="!current">الطالب ليس في رحلة الآن.</div>
          <div v-if="current">
            <div v-if="current.locations[0]">
              <l-map
                :zoom="zoom"
                :center="[current.locations[0].lat, current.locations[0].long]"
              >
                <l-tile-layer :url="url" />
                <l-marker
                  :lat-lng="[
                    current.locations[0].lat,
                    current.locations[0].long,
                  ]"
                >
                  <l-icon>
                    <b-img
                      style="
                        border: dashed 2px red;
                        border-radius: 50%;
                        padding: 5px;
                        object-fit: cover;
                      "
                      :src="
                        student.image
                          ? student.image
                          : require('@/assets/images/avatars/avatar2.png')
                      "
                      height="50"
                      width="50"
                    />
                  </l-icon>
                </l-marker> </l-map
              ><br />
              <div class="col-12">
                المشرف: {{ adminTitle(current.admin_id).name }}
              </div>
              <div class="col-12">
                خط السير: {{ groupTitle(current.group_id).title }}
              </div>
              <div class="col-12">
                الباص: {{ busTitle(current.bus_id).title }}
              </div>
              <div class="col-12" v-if="current.locations[0]">
                آخر تحديث:
                {{ current.locations[0].time.replace("T", " ").split(".")[0] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 c col-lg-8">
      <div class="row">
        <div class="col-12 col-lg-4 g">
          <div class="card card-body">
            <h1 v-if="report.status_1 && report.status_2">
              {{ report.status_1 + report.status_2 }}
            </h1>
            <h1 v-if="report.status_1 && !report.status_2">
              {{ report.status_1 }}
            </h1>
            <h1 v-if="!report.status_1 && report.status_2">
              {{ report.status_2 }}
            </h1>
            <h1 v-if="!report.status_1 && !report.status_2">--</h1>
            <span>
              <i class="fa fa-sign-out"></i>
              مرات ركوب ونزول الباص
            </span>
          </div>
        </div>
        <div class="col-12 col-lg-4 g">
          <div class="card card-body">
            <h1>{{ report.status_3 ? report.status_3 : "--" }}</h1>
            <span>
              <i class="fa fa-user-times"></i>
              الاستئذان عن الحضور
            </span>
          </div>
        </div>
        <div class="col-12 col-lg-4 g">
          <div class="card card-body">
            <h1>{{ report.status_4 ? report.status_4 : "--" }}</h1>
            <span>
              <i class="fa fa-user-times"></i>
              مرات الغياب
            </span>
          </div>
        </div>
        <div class="col-12 g" v-if="logs.length">
          <div class="card card-body">
            <p>
              سجل النشاطات
              <a
                href="javascript:;"
                class="btn btn-sm btn-secondary"
                @click="printTable('t_1')"
              >
                <i class="fa fa-print"></i> طباعة</a
              >
            </p>
            <div class="col-12 table-responsive" id="t_1">
              <table class="table table-hover table-bordered custom-table">
                <thead>
                  <th>التاريخ</th>
                  <th>الباص وخط السير</th>
                  <th>الحالة</th>
                  <th>ملاحظات</th>
                  <th class="no">التفاصيل</th>
                </thead>
                <tbody>
                  <tr v-for="log in logs" :key="log.rand">
                    <td>
                      {{ log.date }}
                    </td>
                    <td>
                      {{ busTitle(log.bus_id).title }}
                      <br />
                      {{ groupTitle(log.group_id).title }}
                    </td>
                    <td>
                      <span
                        class="badge bg-warning"
                        v-if="log.student.status == 0"
                      >
                        لم يتم التحديد
                      </span>
                      <span
                        class="badge bg-info"
                        v-if="log.student.status == 1"
                      >
                        استقل الباص
                      </span>
                      <span
                        class="badge bg-success"
                        v-if="log.student.status == 2"
                      >
                        نزل من الباص
                      </span>
                      <span
                        class="badge bg-primary"
                        v-if="log.student.status == 3"
                      >
                        استأذن عن الحضور
                      </span>
                      <span
                        class="badge bg-danger"
                        v-if="log.student.status == 4"
                      >
                        غائب
                      </span>
                    </td>
                    <td>
                      {{ log.student.details }}
                      <br />
                      <i
                        ><span
                          v-if="log.student.notification_sent == 1"
                          class="text-muted"
                        >
                          <i class="fa fa-check"></i>
                          تم ارسال اشعار باقتراب الباص</span
                        ></i
                      >
                    </td>
                    <td class="no">
                      <button
                        class="btn btn-block"
                        style="border-radius: 0"
                        @click="details(log.logs)"
                        v-b-modal.modal-2
                      >
                        <i class="fa fa-list"></i>
                        عرض التفاصيل
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-2"
      title="التفاصيل"
      size="lg"
      ok-only
      ok-title="إغلاق، شكراً لك"
    >
      <div class="col-12 table-responsive">
        <table class="table table-hover table-bordered custom-table">
          <thead>
            <th>التوقيت</th>
            <th>المشرف</th>
            <th>حالة الطالب</th>
            <th>التفاصيل</th>
          </thead>
          <tbody>
            <tr v-for="log in d" :key="log.time">
              <td>
                {{ log.time }}
              </td>
              <td>
                {{ adminTitle(log.admin_id).name }}
              </td>
              <td>
                <span class="badge bg-danger" v-if="log.status == 0">
                  لم يتم التحديد
                </span>
                <span class="badge bg-danger" v-if="log.status == 6">
                  غائب
                </span>
                <span class="badge bg-info" v-if="log.status == 1">
                  استقل الباص
                </span>
                <span class="badge bg-success" v-if="log.status == 2">
                  نزل من الباص
                </span>
                <span class="badge bg-primary" v-if="log.status == 3">
                  استأذن عن الحضور
                </span>
                <span class="badge bg-warning" v-if="log.status == 4">
                  سيأتي بالباص ولن يعود به
                </span>
                <span class="badge bg-warning" v-if="log.status == 5">
                  سأتي ويعود بدون باص
                </span>
              </td>
              <td>
                {{ log.details }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BImg, BFormGroup, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
delete Icon.Default.prototype._getIconUrl;
import { LMap, LTileLayer, LMarker, LIcon, LPolyline } from "vue2-leaflet";
import { latLng, icon, Icon } from "leaflet";
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
  components: {
    BImg,
    BFormGroup,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPolyline,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      zoom: 13,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      ppoint: null,
      points: [],
      polyline: {
        latlngs: [],
        latlngs2: [],
        color: "green",
        color2: "red",
      },
      icon: icon({
        iconUrl: require("@/assets/images/bus.png"),
        iconSize: [50, 50],
        iconAnchor: [16, 37],
      }),
      ///////
      student: {},
      report: {},
      loading: false,
      parents: [],
      parent: {},
      current: {
        locations: [],
      },
      logs: [],
      start: null,
      end: null,
      admins: [],
      busses: [],
      groups: [],
      d: [],
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    if (!checkPer("students-view")) {
      this.$router.push("/per");
      return false;
    }
    var g = this;
    $.post(api + "/user/busses/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.busses = r.response;
    });
    $.post(api + "/user/admins/list", {
      jwt: g.user.jwt,
      bus: 1,
    }).then(function (r) {
      g.admins = r.response;
    });
    $.post(api + "/user/groups/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.groups = r.response;
    });
    $.post(api + "/user/parents/list-names", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.parents = r.response;
      $.post(api + "/user/students/student", {
        jwt: g.user.jwt,
        id: window.location.href.split("/overview/")[1],
      }).then(function (r) {
        var a = r.response;
        g.student = a;
        g.parent = g.getParent(a.parent_id);
        $.post(api + "/user/students/report", {
          jwt: g.user.jwt,
          student_id: window.location.href.split("/overview/")[1],
        }).then(function (r) {
          var a = r.response;
          g.report = a.count;
          g.logs = a.report;
        });
        $.post(api + "/user/students/current", {
          jwt: g.user.jwt,
          student_id: window.location.href.split("/overview/")[1],
        }).then(function (r) {
          var a = r.response;
          g.current = a;
        });
      });
    });
  },
  methods: {
    getParent(id) {
      var p = {};
      this.parents.forEach(function (r) {
        if (r._id == id) {
          p = r;
        }
      });
      return p;
    },
    busTitle(id) {
      var p = {};
      this.busses.forEach(function (r) {
        if (r._id == id) {
          p = r;
        }
      });
      return p;
    },
    groupTitle(id) {
      var p = {};
      this.groups.forEach(function (r) {
        if (r._id == id) {
          p = r;
        }
      });
      return p;
    },
    adminTitle(id) {
      var p = {};
      this.admins.forEach(function (r) {
        if (r._id == id) {
          p = r;
        }
      });
      return p;
    },
    details(logs) {
      this.d = logs;
    },
    checkPer(e) {
      return checkPer(e);
    },
    getReport() {
      var g = this;
      g.loading = true;
      $.post(api + "/user/students/report", {
        jwt: g.user.jwt,
        student_id: window.location.href.split("/overview/")[1],
        start_date: g.start,
        end_date: g.end,
      }).then(function (r) {
        g.loading = false;
        var a = r.response;
        g.report = a.count;
        g.logs = a.report;
      });
    },
    printTable(id) {
      var divToPrint = document.getElementById(id);
      var newWin = window.open("");
      newWin.document.write(
        `<style>*{direction:rtl} .no{display:none;}table, td, th {
        border: 1px solid;
      }

      table {
        width: 100%;
        border-collapse: collapse;
      }</style>` + divToPrint.innerHTML
      );
      newWin.print();
      newWin.close();
    },
  },
};
</script>
<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.5/leaflet.css";
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style><style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>